import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import axios from 'axios'
Vue.prototype.$axios = axios
import qs from 'qs';
Vue.prototype.$qs = qs;
import mycom from './utils/mycomponent'
Vue.use(mycom);
Vue.prototype.$basehttp = "https://www.seengene.com/node/api/mail";
Vue.prototype.$basepdf = "https://www.seengene.com/node/api/uploadPdf";
Vue.prototype.$basepic = "https://www.seengene.com/node/api/uploadPic";
import './assets/index.scss'
Vue.prototype.$bus = new Vue()
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
