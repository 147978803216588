export default (Vue) =>{
  Vue.component("Myswiper",()=>import ('../components/myswiper'))
  Vue.component("Heade",()=>import ('../views/heade'))
  Vue.component("Teamwork",()=>import ('../components/teamwork'))
  Vue.component("Mydialog",()=>import ('../components/mydialog'))
  Vue.component("Mydetails",()=>import ('../components/mydetails'))
  Vue.component("Cooperation",()=>import ('../components/cooperation'))
  Vue.component("Rightitem",()=>import ('../components/rightitem'))
}


