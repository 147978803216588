import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import Index from '../views/index.vue'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    redirect:"index",
    component:Home,
    children: [
      {
        path: '/index',
        name: 'index',
        component: Index
      },
      {
        path: '/arto',//ARTO
        name: 'arto',
        component: () => import('../views/yao.vue'),
      },
      {
        path: '/artos',//ARTOS
        name: 'artos',
        component: () => import('../views/yaoyuzhou.vue'),
      },
      {
        path: '/artown',//ARTOWN
        name: 'artown',
        component: () => import('../views/yaoshijie.vue'),
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('../views/about.vue'),
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('../views/login.vue'),
      },
      {
        path: '/delivery',
        name: 'delivery',
        component: () => import('../views/delivery.vue'),
      },
    ]
  },
]
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
// router.beforeEach((to, from, next) => {
//   if (to.path === '/login') return next()
//   // 获取token
//   const token = window.localStorage.getItem('token')
//   // console.log(token)
//   if (token === null) {
//     Vue.prototype.$message('请登录帐号')
//     router.replace({name:'login'})
//     // return next()
//   }
//   next()
// })
export default router
