<template>
    <div class="allwrap" >
        <el-container >
          <Teamwork/>
          <Heade/>
          <el-main>
            <router-view />
          </el-main>
        </el-container>
        <div class="footwrap" v-if="flag">
          <footer class="myflex myfooter">
            <div class="leftwrap">
              <img class="footerleft" src="../assets/index/logo.png" @click="jump()" alt="">
            </div>
            <div class="center8">
              <div class="tclass">T: 010-5717-1177 </div>
              <div class="right8">
                <p style="margin-left: -39px;"><span >E: </span> <span>商务合作</span> BD@seengene.com</p>
                <p> <span>求职投递</span> HR@seengene.com </p>
                <p> <span>投资意向</span> CEO@seengene.com</p>
              </div>
            </div>
            <div class="basetext"><img style="width:110px;height:110px" src="../assets/index/erweima.png" alt=""><p>关注公众号</p></div>
          </footer>
          <pre class="bottom8">北京市海淀区安宁庄后街京玺创意园AR基地 | 京ICP备 16013236号-1 | Copyright Seengene Inc.,All rights reserved</pre>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      flag: true,
    }
  },
  methods: {
    jump() {
      this.$router.push({path:"index"})
    }
  },
  watch:{
    $route:{
      immediate:true,
      handler(){
        if(this.$route.name == "about"){
          this.flag = false;
        }else{
          this.flag = true;
        }
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.el-main {
  width: 100%;
  text-align: center;
  padding: 0;
  overflow: hidden;
}
.myflex{
  display: flex;
  justify-content: space-between ;
}
.footwrap{
  width: 100%;
  background: #000;
}
  .myfooter{
    width: 1200px;
    margin: 0 auto;
    background: #000;
    height: 244px;
    box-sizing: border-box;
    color: #ffffff;
    padding-top: 64px;
    .leftwrap{
      text-align: left;
    }
    .basetext{
      text-align: center;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 18px;
    }
    .footerleft{
      width: 123px;
      height: 39px;
      cursor: pointer;
    }
    .center8{
      display: flex;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 24px;
      margin-left: 100px;
      .right8{
        text-align: left;
        margin-left: 64px;
        margin-left: 104px;
        p{
          margin-bottom: 20px;
        }
        span{
          margin-right: 20px;
        }
      }
    }
  }
  .bottom8{
    text-align: center;
    background: #141414;
    margin: 0 ;
    padding: 0;
    height: 40px;
    line-height: 40px;

    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
  }
</style>
