<template>
  <div id="app" >
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
    mounted () {
    var isMobile = /Android|webOS|iPhone|iPod|iPad|BlackBerry/i.test(navigator.userAgent);
    if (isMobile) {
      window.location.href = "http://101.34.188.4:8080/xjh5/#/index"
    }else{
      // console.log("pc",navigator.userAgent);
      // window.location.href = "http://101.34.188.4:8080/xjweb/#/index"
    }
  },
}
</script>
<style>
.v-modal{
  height: 110% !important;
}
.el-dialog__wrapper{
  height: 110vh;
  background-size: contain;
}
#app{
  width: 100%;
  height: 110vh;
  overflow-y: scroll;
}
.el-radio__input.is-checked .el-radio__inner{
  border-color: none;
}
body::-webkit-scrollbar {
  width: 0px;
}
.scroll-container {
  width: 100%;
  overflow: auto;
}
body,
html {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-family: PingFang SC;
  height: 100%;
}
ul,
p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
i,
div {
  margin: 0;
  padding: 0;
  list-style: none;
  font-style: normal;
}
</style>
