<template>
  <div ref="indexTop">
    <Rightitem/>
    <Mydetails :detailsTitle="detailsTitle" :detailsFlag="detailsFlag" @resetdetailsFlag="resetdetailsFlag" :showVideo="showVideo" :myPoster="myPoster" :textDetil="textDetil" />
    <Mydialog :fromTitle="fromTitle" :dialogflag="dialogflag" @resetdialogflag="resetdialogflag"/>
    <Cooperation :dialogFlagCooperation="dialogFlagCooperation" @resetCooperation="resetCooperation"/>
    <div class="bgmain2" ref="globalScroll">
        <p class="totletitle" >
          <span class="title1">悉见产品</span>
        </p>
        <p class="title3">地球级 AR 空间计算+AIGC 场景智能平台+Web3 虚实共生经营</p>
        <div class="box-wrap">
            <div class="item-21 " v-for="(item,index) in threebg2" :key="index">
                <img class="bgimg-2" :src="item.url"/>
                <div class="box-21">
                  <p><span class="text-21"> {{ item.title1 }}</span> <span class="text-22"> {{ item.title2 }}</span></p>
                  <div class="myflex">
                    <img class="imgicon-21" :src="item.url2" alt="">
                    <div class="text-wrap">
                      <p class="text-23" v-html="item.text2"></p>
                      <!-- <p class="text-23">{{item.text1}}</p>
                      <span class="text-24" v-html="item.text2"></span> -->
                    </div>
                  </div>
                </div>
                <div class="btn-21" @click="newdialog(index)"><p>{{ item.btn }}</p></div>
            </div>
        </div>
    </div>

    <div class="bgmain3">
      <div class="width-fixed">
        <div class="rightwrap">
          <div class="gather" @click="jump(4)"><p>采集开发指南</p></div>
          <div class="exploit" @click="jump(5)"><p>立即开发</p></div>
          <div class="hardware" @click="jump(6)"><p>硬件适配</p></div>
        </div>
        <img class="rightimg3" src="../assets/index/beijingyou.png" alt="">
        <img class="leftimg3" src="../assets/index/beijingzuo.png" alt="">

        <div class="flp">
          <div class="box-31">
            <span class="big-31">爻图</span>
            <p class="text-30">先进的AR大空间引擎，轻松把任意空间改造成AR元宇宙</p>
          </div>
          <div class="boxwrap3">
            <div class="fourinner">
              <div :class="[index % 2 == 0 ?'actlow':'','itemfour']" v-for="(item,index) in fourimg" :key="index">
                <div class="itemfour" v-if="index % 2 == 0">
                  <div class="upnum" @mouseenter="showbottomicon(index)" @mouseleave="closeicon(index)">
                    <div class="leftnum">{{item.num}}</div>
                    <div class="rightnum">
                      <p class="title31">{{item.title1}}</p>
                      <p class="title32">{{item.title2}}</p>
                    </div>
                  </div>
                  <img class="buildimg" :src="item.url" alt="">
                  <div class="bottomicon" :ref="'showicon' + index">
                    <div class="bgcolor"><img :src="item.icon1" alt=""> <span>{{item.span1}}</span></div>
                    <div class="bgcolor"><img :src="item.icon2" alt=""> <span>{{item.span2}}</span></div>
                    <div class="bgcolor"><img :src="item.icon3" alt=""> <span>{{item.span3}}</span></div>
                    <div class="bgcolor"><img :src="item.icon4" alt=""> <span>{{item.span4}}</span></div>
                  </div>
                </div>

                <div class="itemfour" v-else>
                  <div class="bottomicon2" :ref="'showicon' + index">
                    <div class="bgcolor"><img :src="item.icon1" alt=""> <span>{{item.span1}}</span></div>
                    <div class="bgcolor"><img :src="item.icon2" alt=""> <span>{{item.span2}}</span></div>
                    <div class="bgcolor"><img :src="item.icon3" alt=""> <span>{{item.span3}}</span></div>
                    <div class="bgcolor"><img :src="item.icon4" alt=""> <span>{{item.span4}}</span></div>
                  </div>

                  <img class="buildimg topimg" :src="item.url" alt="">
                  <div class="upnum" @mouseenter="showbottomicon(index)" @mouseleave="closeicon(index)">
                    <div class="leftnum">{{item.num}}</div>
                    <div class="rightnum">
                      <p class="title31">{{item.title1}}</p>
                      <p class="title32">{{item.title2}}</p>
                    </div>
                  </div>
                </div>
                <!-- <div class="bottomicon" >
                  <div class="bgcolor"><img :src="item.icon1" alt=""> <span>{{item.span1}}</span></div>
                  <div class="bgcolor"><img :src="item.icon2" alt=""> <span>{{item.span2}}</span></div>
                  <div class="bgcolor"><img :src="item.icon3" alt=""> <span>{{item.span3}}</span></div>
                  <div class="bgcolor"><img :src="item.icon4" alt=""> <span>{{item.span4}}</span></div>
                </div> -->
              </div>
            </div>
          </div>
        </div>

        <div class="box-wrap31 myflexbe">
          <div class="item-32" v-for="(item,index) in fourbg3" :key="index">
            <div class="absline"></div>
            <div class="icontitle">
              <img class="imgicon3" :src="item.url"/>
              <span class="text-33">{{ item.title1 }}</span>
            </div>
            <p class="text-34" v-html="item.title2 "></p>
            <!-- <div class="shotline"></div>
            <p class="text-35" v-html="item.title3"></p> -->
          </div>
        </div>
        <div class="btn3wrap"><div class="btn3" @click="jump(5)"><p>爻图管理平台</p></div></div>
      </div>
    </div>

    <div class="bgmain4">
      <div class="boxtop4">
        <!-- <span class="title43">ARTOS</span> -->
        <p class="title4"><span class="title42">爻宇宙</span> </p>
        <pre class="title44">AR+VR全端映射平台 | 一站式打造虚实互通元宇宙</pre>
        <div>
          <div class="position4">
            <img class="Virtual_world" src="../assets/index/Virtual_world.png" alt="">
            <img class="jiantoudown" src="../assets/index/jiantoudown.png" alt="">
            <img class="jiantou" src="../assets/index/jiantou.png" alt="">
            <img class="Real_world" src="../assets/index/Real_world.png" alt="">

            <div class="orientation">
              <p class="up4">Synchronization by AR</p>
              <p class="down4">3D Spatial Map Update</p>
              <p class="left4">Virtual world</p>
              <p class="right4">Real world</p>
              <div class="both4">
                <p class="title46">1:1虚拟世界</p>
                <p>线上随时随地</p>
                <p>VR眼镜+手机+小程序</p>
              </div>
              <div class="small4">
                <p class="title46">爻图</p>
                <p>空间计算</p>
              </div>
              <div class="both41">
                <p class="title46">AR真实世界</p>
                <p>线下虚实共生</p>
                <p>AR眼镜+手机+小程序</p>
              </div>
            </div>
          </div>
          <pre class="title45">AR/VR线上线下1:1基座重建  +  场景社交互娱体验商业开发  +  虚拟化身全终端实时互通交互  +  游戏化经济与空间商业系统</pre>
        </div>
      </div>

      <div class="box-wrap41 myflexbe">
        <div class="item-42 myleft" v-for="(item,index) in fourbg4" :key="index">
          <div class="top42">
            <img class="img4" :src="item.url"/>
            <p class="text-41">{{ item.title1 }}</p>
          </div>
          <p class="text-42" v-html="item.title2 "></p>
        </div>
      </div>
      <!-- <p class="bottom4">先进的AR大空间引擎，轻松三步把任意空间改造成AR元宇宙</p> -->
    </div>

    <div class="bgmain5">
      <div class="titlewrap"><p class="title51">为文商旅客户一站式打造全场景、全要素、可持续经营的虚实共生元宇宙</p></div>
      <!-- <p class="title51">文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明明文明文字说明文字说明</p> -->
      <div class="layout">

        <div class="itemwrap5">
          <div class="itemleft5" v-for="(item,index) in fivebg5" :key="index" @click="imgchange(index)">
            <img ref="img" @mouseenter="imgenter(index)" @mouseleave="imgleave(index)" :class="[activity == index ?'actclassimg': '','img51' ]" :src="item.url"/>
          </div>
        </div>

        <div class="right5">
          <div v-for="(item,index) in fivetitle5" class="text51" :key="index" @mouseenter="imgenter(index)" @mouseleave="imgleave(index)">
            <p :class="[activity == index ?'actclass': '','text51' ]" >{{ item.title }}</p>
            <div :class="[activity == index ?'actmyline': '']"></div>
          </div>
        </div>

        <div ref="subref" class="subitemwrap" v-for="(itemsub,index) in fivesub5" :key="index">
          <p class="subitem5" v-for="(sub,index) in itemsub.sub" :key="index">{{ sub }}</p>
        </div>

      </div>

    </div>

    <div class="bgmain6">
      <img class="left6" src="../assets/index/6left.png" alt="">
      <div class="box-wrap6 myflex">
        <div class="item-6 myleft" v-for="(item,index) in fourbg6" :key="item + index">
          <div class="itemtop6">
            <div class="btn6">{{ item.btn }}</div>
            <div class="text6">
              <p>
                <span>{{ item.text1 }}</span>
                <span>{{ item.text2 }}</span>
              </p>
              <p style="margin-top:18px">
                <span>{{ item.text3 }}</span>
                <span>{{ item.text4 }}</span>
              </p>
            </div>
          </div>
          <div class="item-61">
            <img class="bofangimg" src="../assets/index/bofang.png" alt="" @click="details(item.title2 )">
            <img style="width:282px;height:159px" :src="item.url"/>
            <div class="playicon">
              <div class="playbtn"></div>
            </div>
            <div class="iteminner6">
              <p class="text-61">{{ item.title2 }}</p>
              <p class="text-62">{{ item.title3 }}</p>
              <div class="btn61" @click="details(item.title2 )"><p>查看详情</p></div>
            </div>
          </div>
        </div>
      </div>
      <img class="right6" src="../assets/index/6right.png" alt="">
    </div>

    <div class="bgmain7">
      <p class="title71"><span class="span71">悉部世界</span></p>
      <p class="title72">将现实世界地标（景区/园区/商圈等）改造成虚实共生永续的AR X AI元宇宙乐园</p>
      <div class="totle71">
        <div class="box71">
          <div class="box72">
            <img style="width:266px;height:201px" src="../assets/index/shiyitu.png" alt="">
            <div class="title73">ARBox成Worlds线下触点</div>
            <p class="title79">集装箱复制模式全国覆盖</p>
            <div class="mynpcwrap">
              <img class="mynpc" src="../assets/index/jizhuangxiang.png" alt="">
              <img class="mynpc" src="../assets/index/jizhuangxiang.png" alt="">
              <img class="mynpc" src="../assets/index/jizhuangxiang.png" alt="">
              <img class="mynpc" src="../assets/index/jizhuangxiang.png" alt="">
            </div>
          </div>
          <div class="biaotibox rightbox">Box</div>
        </div>

        <div class="box73">
          <div class="top71">
            <p class="p71">AGI驱动更有趣味的NPC角色</p>
            <div class="mynpcwrap">
              <img class="mynpc" src="../assets/index/NPC.png" alt="">
              <img class="mynpc" src="../assets/index/NPC.png" alt="">
              <img class="mynpc" src="../assets/index/NPC.png" alt="">
              <img class="mynpc" src="../assets/index/NPC.png" alt="">
            </div>
            <p class="lefttext">AGI驱动持续生长的剧本故事</p>
          </div>
          <div class="biaotiagi topbox">AGI</div>
          <div class="zubiao">
            <div>
              <p>悉部</p>
              <p>世界</p>
            </div>
          </div>
          <div class="biaotiagi bottombox">AR</div>
          <div class="top71">
            <p class="p71">AR让虚实共生商业充满价值</p>
            <div>
              <img class="mynpc2" src="../assets/index/ditu.png" alt="">
              <img class="mynpc2" src="../assets/index/ditu.png" alt="">
              <img class="mynpc2" src="../assets/index/ditu.png" alt="">
              <img class="mynpc2" src="../assets/index/ditu.png" alt="">
            </div>
            <p class="bottomtext">AR地图无额外空间运营成本</p>
          </div>

        </div>

        <div class="box71 newright">
          <div class="biaotibox leftbox">Open</div>
          <div class="box72 box-right">
            <p class="toptext">开放AR+AGI能力创作平台</p>
            <div class="mynpcwrap">
              <img class="mynpc3" src="../assets/index/chuangzuo.png" alt="">
              <img class="mynpc3" src="../assets/index/chuangzuo.png" alt="">
              <img class="mynpc3" src="../assets/index/chuangzuo.png" alt="">
              <img class="mynpc3" src="../assets/index/chuangzuo.png" alt="">
            </div>
            <img style="width:266px;height:200px" src="../assets/index/shiyituy.png" alt="">
            <div class="title74">数字身份构建社交开放世界</div>
          </div>
        </div>
      </div>
      <div class="btn71">
        <p class="btnsolo" @click="jump(1)"><span>加盟版</span></p>
        <p class="btnsolo" @click="jump(2)"><span>旗舰版</span></p>
        <p class="btnsolo" @click="jump(3)"><span>城市版</span></p>
      </div>
    </div>
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import 'swiper/dist/css/swiper.css'
export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      textDetil:"",
      isAutoChange:"",
      fromTitle:"",
      dialogflag: false,
      dialogFlagCooperation:false,
      detailsTitle:"",
      detailsFlag: false,
      showVideo:"",
      myPoster:'',
      activity:0,
      flag:true,
      swiperOption: {
        loop: true, // 是否循环
        slidesPerView: 1,
        observer: true, //开启动态检查器，监测swiper和slide
        slidesPerView: "auto", //只swiper里的宽度自适应A
        autoplay: {
          delay: "3000",
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          bulletClass : 'swiper-pagination-bullet',
          bulletActiveClass: 'my-bullet-active',
        },
        on: {
            slideChangeTransitionEnd: () => {
                // 在这里编写当slide切换结束时要执行的代this.$refs.mySwiper.swiper码
                if(this.$refs.mySwiper.swiper != null){
                    // console.log(this.$refs.mySwiper.swiper.realIndex);
                    if(this.$refs.mySwiper.swiper.realIndex == 0){
                      this.$refs.mySwiper.swiper.autoplay.stop();
                      // 更改delay属性
                      this.$refs.mySwiper.swiper.params.autoplay.delay = 3000;
                      // 启动轮播
                      this.$refs.mySwiper.swiper.autoplay.start();
                    }
                    if(this.$refs.mySwiper.swiper.realIndex == 1){
                        this.$refs.mySwiper.swiper.autoplay.stop();
                        // 更改delay属性
                        this.$refs.mySwiper.swiper.params.autoplay.delay = 12000;
                        // 启动轮播
                        this.$refs.mySwiper.swiper.autoplay.start();
                    }
                }
            },
        },
      },
      threebg2: [
        {
          url: require("../assets/index/1.png"),
          url2: require("../assets/index/2-1.png"),
          title1: "爻图",
          title2: "AR空间计算引擎/To D",
          text1: "跑马圈地，占领场景",
          // text2: "中国第一的AR空间地图引擎服务<br/>3D互联网的地球级虚实空间底座",
          text2: "领先的空间地图引擎服务<br/>地球级 AR 空间互联网底座",
          btn:"我是开发者"
        },
        {
          url: require("../assets/index/2.png"),
          url2: require("../assets/index/2-2.png"),
          title1: "爻宇宙",
          title2: "AIGC场景平台/To B",
          text1: "开放平台，构建应用",
          text2: "一站式的 AIGC 开放平台<br/>广泛适用各类文商旅场景",
          btn:"我是需求方"
        },
        {
          url: require("../assets/index/3.png"),
          url2: require("../assets/index/2-3.png"),
          title1: "悉部世界",
          title2: "Web3经营/To C",
          text1: "留存用户，经营创收",
          text2: "NPC 智能驱动的开放世界<br/>打造线下流量空间元宇宙",
          btn:"我是加盟商"
        },
      ],
      fourimg:[
        {
          num:"01",
          title1: "众包采集",
          title2:"真实世界环境数据",
          url: require("../assets/index/2.5d-01.png"),
          icon1: require("../assets/index/xiangjicaiji@2x.png"),
          icon2: require("../assets/index/xiaofeiji@2x.png"),
          icon3: require("../assets/index/quanshebei@2x.png"),
          icon4: require("../assets/index/quanqiuhua@2x.png"),
          span1:"零门槛扫描",
          span2:"消费级众包",
          span3:"全设备支持",
          span4:"全球化渠道",
        },
        {
          num:"02",
          title1: "全自动化",
          title2:"空间高精地图重建",
          url: require("../assets/index/2.5d-02.png"),
          icon1: require("../assets/index/limiji@2x.png"),
          icon2: require("../assets/index/chengshiji@2x.png"),
          icon3: require("../assets/index/quanzidong@2x.png"),
          icon4: require("../assets/index/quanchangjing@2x.png"),
          span1:"厘米级精度",
          span2:"城市级尺度",
          span3:"全自动重建",
          span4:"全场景适用",
        },
        {
          num:"03",
          title1: "AIGC交互式",
          title2:"AR世界3D创作编辑",
          url: require("../assets/index/2.5d-03.png"),
          icon1: require("../assets/index/didaima@2x.png"),
          icon2: require("../assets/index/aigc@2x.png"),
          icon3: require("../assets/index/mokuaihua@2x.png"),
          icon4: require("../assets/index/pingtaigongchuang@2x.png"),
          span1:"低代码编辑",
          span2:"AIGC辅助",
          span3:"模块化玩法",
          span4:"平台化共创",
        },
        {
          num:"04",
          title1: "空间互联",
          title2:"全终端多人AR体验",
          url: require("../assets/index/2.5d-04.png"),
          icon1: require("../assets/index/quanzhongduan@2x.png"),
          icon2: require("../assets/index/shishigaobengfa@2x.png"),
          icon3: require("../assets/index/duopingxing@2x.png"),
          icon4: require("../assets/index/quankongjian@2x.png"),
          span1:"全终端适配",
          span2:"实时高并发",
          span3:"多平行世界",
          span4:"全空间互联",
        },
      ],
      ninebg3:[
        {
          url: require("../assets/index/31.png"),
          title: "普通全景相机采集",
        },
        {
          url: require("../assets/index/32.png"),
          title: "全自动AR地图重建",
        },
        {
          url: require("../assets/index/33.png"),
          title: "低门槛开发上线",
        },
        {
          url: require("../assets/index/34.png"),
          title: "支持超大空间场景",
        },
        {
          url: require("../assets/index/35.png"),
          title: "厘米级高精度定位",
        },
        {
          url: require("../assets/index/36.png"),
          title: "支持海量用户并发",
        },
        {
          url: require("../assets/index/37.png"),
          title: "小程序/APP/AR眼镜",
        },
        {
          url: require("../assets/index/38.png"),
          title: "支持室内外全场景",
        },
        {
          url: require("../assets/index/39.png"),
          title: "支持城市级云服务",
        },
        {
          // url: require("../assets/index/39.png"),
          // title: "支持城市级云服务",
        },
      ],
      fourbg3:[
        {
          url: require("../assets/index/331.png"),
          title1: "消费级采集",
          title2: "使用消费级的全景相机/手机<br/>普通用户通过简单学习即可掌握",
          title3: "地图重建算法消耗悉见云计算资源<br/>根据面积/订阅批量等方式收费，一天交付",
        },
        {
          url: require("../assets/index/332.png"),
          title1: "自动化建图",
          title2: "自研的AI深度学习框架<br/>全自动高精度地图重建流水线",
          title3: "每个用户终端都须调用AR空间定位服务<br/>按月/年以QPS并发规模进行订阅收费",
        },
        {
          url: require("../assets/index/333.png"),
          title1: "AIGC创作编辑",
          title2: "AIGC辅助3D场景编辑器<br/>低代码模块化大幅降低创作门槛",
          title3: "适用于对定位并发数据量大、地域性用户数据私有<br/>安全的客户<br/>按年/服务器台数进行私有化部署与授权服务收费<br/>一天交付",
        },
        {
          url: require("../assets/index/334.png"),
          title1: "全类型终端",
          title2: "支持手机/眼镜/定制设备<br/>同一空间多人实时互动与协作",
          title3: "适用于平台级/城市级客户、地域性用户数据私有<br/>安全的客户<br/>按私有化部署授权 + 年/服务器台数授权服务收费<br/>一周交付",
        },
      ],
      fourbg4:[
        {
          url: require("../assets/index/41.png"),
          title1: "真虚实共生",
          title2: "基于强大的AR空间计算引擎<br/>数字世界与真实世界永续融合",
        },
        {
          url: require("../assets/index/42.png"),
          title1: "全场景兼容",
          title2: "支持小到展厅沙盘大到城市<br/>文旅/商业/街区/园区等全场景",
        },
        {
          url: require("../assets/index/43.png"),
          title1: "全要素集成",
          title2: "一站式集成元宇宙平台全要素<br/>AI+AR+化身+经济+玩法+社交",
        },
        {
          url: require("../assets/index/44.png"),
          title1: "全模式互通",
          title2: "支持手机APP/AR眼镜/小程序<br/>支持AR与VR全打通实时交互",
        },
      ],
      fivebg5:[
        { url: require("../assets/index/L5.png") },
        { url: require("../assets/index/L4.png") },
        { url: require("../assets/index/L3.png") },
        { url: require("../assets/index/L2.png") },
        { url: require("../assets/index/L1.png") },
      ],
      fivetitle5:[
        {
          title:"L5 商业经营层",
          sub:[
          "Web3经济系统",
          "创作者经济",
          "世界会员订阅",
          "空间广告联盟",
          "空间数字电商",
          "数字门票分账",
          ]
        },
        {
          title:"L4 开放生态层",
          sub:[
          "开放地图众包",
          "开放剧本创作",
          "开放场景众创",
          "开放城市渠道加盟",
          ]
        },
        {
          title:"L3 功能体验层",
          sub:[
          "XR导航导览",
          "XR游戏化营销",
          "XR文旅剧本游",
          "XR数字人导游",
          "XR社交/养成",
          "数十种XR玩法",
          ]
        },
        {
          title:"L2 引擎工具层",
          sub:[
          "AIGC引擎",
          "AGI NPC系统",
          "开放世界系统",
          "空间智能引擎",
          "虚拟经济系统",
          "虚拟社交系统",
          ]
        },
        {
          title:"L1 虚实基座层",
          sub:[
          "AR底座:城市空间地图",
          "VR底座:虚拟世界基建",
          ]
        },
      ],
      fivesub5:[
        {
          sub:[
            "Web3经济系统",
            "创作者经济",
            "世界会员订阅",
            "空间广告联盟",
            "空间数字电商",
            "数字门票分账",
            ]
        },
      ],
      fourbg6:[

        {
          url: require("../assets/index/1.jpg"),
          btn: "文旅类场景",
          text1: "AR文化景区",
          text2: "AR主题古镇",
          text3: "AR沉浸街区",
          text4: "AR特色园区",
          title2:"颐和园的秘密",
          title3:"颐和园的秘密，通过对仁寿殿创建高精度空间地图，虚拟的麒麟、仁寿殿与真实场景完美融合，为游客带来沉浸式的MR互动导览体验。"
        },
        {
          url: require("../assets/index/2.jpg"),
          btn: "商业类场景",
          text1: "AR商业综合体",
          text2: "AR沉浸商业空间",
          text3: "AR商街广场",
          text4: "AR品牌旗舰店",
          title2:"三里屯像素嘉年华",
          title3:"三里屯像素嘉年华，通过对三里屯标志性建筑创建高精度空间地图，将经典的游戏元素植入到现实空间中，把用户带入一场立体的像素嘉年华。"
        },
        {
          url: require("../assets/index/3.jpg"),
          btn: "展览类场景",
          text1: "AR企业展厅",
          text2: "AR展览展会",
          text3: "AR生态园区",
          text4: "AR文博艺术展",
          title2:"科幻大会元宇宙",
          title3:"科幻大会元宇宙，2023中国科幻大会期间，悉见为科幻创新中心提供技术支持，将亦真亦幻的元宇宙呈现给世人，为广大科幻迷带来一场科技盛宴。"
        },
        {
          url: require("../assets/index/4.jpg"),
          btn: "平台类场景",
          text1: "AR主题城市",
          text2: "AR产业元宇宙",
          text3: "AR社交游戏",
          text4: "AR开放世界",
          title2:"保时捷奇幻梦之旅",
          title3:"保时捷奇幻梦之旅，保时捷×悉见创新杰出项目，让保时捷汽车可以像AR眼镜一样工作，成为运行在城市中的空间计算体验终端。"
        },
      ],
      sixbg8:[
        {
          img1:require("../assets/index/xunzhang.png"),
          img2:require("../assets/index/zhuanqian.png"),
          img3:require("../assets/index/jiangbei.png"),
          title:"开发者",
          text1:"爻图引擎开发AR应用/剧本",
          text2:"择优上架爻宇宙&苹果AR商店 ",
          text3:"优质团队获得更多订单与投资"
        },
        {
          title:"悉见生态",
          text1:"爻图地球计划",
          text2:"百城千图计划",
          text3:"悉部世界计划"
        },
        {
          img1:require("../assets/index/xunzhang.png"),
          img2:require("../assets/index/zhuanqian.png"),
          img3:require("../assets/index/jiangbei.png"),
          title:"采图者",
          text1:"用爻图引擎采集各地AR地图",
          text2:"获得高额AR地图相关业务提成",
          text3:"优先获得加盟运营与合伙权益"
        },
        {
          img1:require("../assets/index/xunzhang.png"),
          img2:require("../assets/index/zhuanqian.png"),
          img3:require("../assets/index/jiangbei.png"),
          title:"投资商",
          text1:"投资悉部世界剧本等AR产品",
          text2:"获得产品运营长期分账权益",
          text3:"优先获得公司资本化投资权益"
        },
        {
          img1:require("../assets/index/xunzhang.png"),
          img2:require("../assets/index/zhuanqian.png"),
          img3:require("../assets/index/jiangbei.png"),
          title:"代理商",
          text1:"代理悉见元宇宙全系列产品",
          text2:"获得相关业务代理销售提成",
          text3:"成为悉见业务或城市合伙人"
        },
        {
          img1:require("../assets/index/xunzhang.png"),
          img2:require("../assets/index/zhuanqian.png"),
          img3:require("../assets/index/jiangbei.png"),
          title:"合伙人",
          text1:"达到业务成就成为悉见合伙人",
          text2:"享有更高分红与股权兑换权",
          text3:"共享公司上市资本化股东权益"
        },
        {
          img1:require("../assets/index/xunzhang.png"),
          img2:require("../assets/index/zhuanqian.png"),
          img3:require("../assets/index/jiangbei.png"),
          title:"加盟商",
          text1:"加盟运营悉部世界等AR产品",
          text2:"获得加盟产品高额运营分账",
          text3:"成为悉见业务或城市合伙人"
        },
      ],
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll,true)
  },
  methods: {
    showbottomicon(index){
      if(index == 0){this.$refs.showicon0[0].style.display = "flex"}
      if(index == 1){this.$refs.showicon1[0].style.display = "flex"}
      if(index == 2){this.$refs.showicon2[0].style.display = "flex"}
      if(index == 3){this.$refs.showicon3[0].style.display = "flex"}
    },
    closeicon(index){
      this.$refs.showicon0[0].style.display ="none"
      this.$refs.showicon1[0].style.display ="none"
      this.$refs.showicon2[0].style.display ="none"
      this.$refs.showicon3[0].style.display ="none"
    },
    handleScroll() {
      this.$nextTick(() => {
        const divElement = this.$refs.globalScroll;
        if (!divElement) {
          return;
        }

        const rect = divElement.getBoundingClientRect();
        const top = rect.top + window.pageYOffset;

        if (top - rect.top < 60) {
          this.$bus.$emit("huifu", "op");
        } else {
          this.$bus.$emit("delfun", 'black');
        }
      });
    },
    details(num){
      this.detailsFlag = true
      if(num == "颐和园的秘密"){ this.detailsTitle = "颐和园的秘密",this.showVideo = require("../assets/1.mp4"),this.myPoster = this.fourbg6[0].url,this.textDetil = this.fourbg6[0].title3}
      if(num == "三里屯像素嘉年华"){ this.detailsTitle = "三里屯像素嘉年华",this.showVideo = require("../assets/2.mp4"),this.myPoster = this.fourbg6[1].url,this.textDetil = this.fourbg6[1].title3}
      if(num == "科幻大会元宇宙"){ this.detailsTitle = "科幻大会元宇宙",this.showVideo = require("../assets/3.mp4"),this.myPoster = this.fourbg6[2].url,this.textDetil = this.fourbg6[2].title3}
      if(num == "保时捷奇幻梦之旅"){ this.detailsTitle = "保时捷奇幻梦之旅",this.showVideo = require("../assets/4.mp4"),this.myPoster = this.fourbg6[3].url,this.textDetil = this.fourbg6[3].title3}
    },
    resetdetailsFlag(){
      this.detailsFlag = false
    },
    jump(name){
      if(name == 1){let routeUrl = this.$router.resolve({path:'/artown#artown0'});window.open(routeUrl.href, '_blank');}
      if(name == 2){let routeUrl = this.$router.resolve({path:'/artown#artown1'});window.open(routeUrl.href, '_blank');}
      if(name == 3){let routeUrl = this.$router.resolve({path:'/artown#artown2'});window.open(routeUrl.href, '_blank');}
      if(name == 4){window.open('https://seengene.feishu.cn/docs/doccnsraMHn3VVqNN3biEU3ZPvb', '_blank')}
      if(name == 5){window.open('https://developer.seengene.com/', '_blank')}
      if(name == 6){let routeUrl = this.$router.resolve({path:'/arto#arto4'});window.open(routeUrl.href, '_blank');}
    },
    resetdialogflag(){
      this.dialogflag = false
    },
    resetCooperation(){
      this.dialogFlagCooperation = false
    },
    newdialog(num){
      if(num == 0){this.dialogflag = true; this.fromTitle = "开发者"}
      if(num == 1){this.dialogFlagCooperation = true; }
      if(num == 2){this.dialogflag = true;this.fromTitle = "加盟商"}
    },
    imgchange(index) {
      this.$nextTick( ()=>{
        this.fivesub5[0].sub = this.fivetitle5[this.activity].sub
      })
      this.activity = index
      if(index == 0){this.$refs.subref[0].style.top = "0 "}
      if(index == 1){this.$refs.subref[0].style.top = "120px"}
      if(index == 2){this.$refs.subref[0].style.top = "240px"}
      if(index == 3){this.$refs.subref[0].style.top = "-30px"}
      if(index == 4){this.$refs.subref[0].style.top = "410px"}
    },
    imgleave(index){
      this.$refs.img[0].style.filter = 'opacity(1) '
      this.$refs.img[1].style.filter = 'opacity(1) '
      this.$refs.img[2].style.filter = 'opacity(1) '
      this.$refs.img[3].style.filter = 'opacity(1) '
      this.$refs.img[4].style.filter = 'opacity(1) '
      this.$refs.img[0].style.zIndex = '50'
      this.$refs.img[1].style.zIndex = '40'
      this.$refs.img[2].style.zIndex = '30'
      this.$refs.img[3].style.zIndex = '20'
      this.$refs.img[4].style.zIndex = '10'
    },
    imgenter(index){
      this.$nextTick( ()=>{
        this.fivesub5[0].sub = this.fivetitle5[this.activity].sub
      })
      this.activity = index
      this.$refs.img[0].style.filter = ' opacity(50%)'
      this.$refs.img[1].style.filter = ' opacity(50%)'
      this.$refs.img[2].style.filter = ' opacity(50%)'
      this.$refs.img[3].style.filter = ' opacity(50%)'
      this.$refs.img[4].style.filter = ' opacity(50%)'
      if(index == 0){this.$refs.subref[0].style.top = "-10px"; this.$refs.img[index].style.zIndex = ' 100'}
      if(index == 1){this.$refs.subref[0].style.top = "120px";this.$refs.img[index].style.zIndex = ' 100'}
      if(index == 2){this.$refs.subref[0].style.top = "0px";this.$refs.img[index].style.zIndex = ' 100'}
      if(index == 3){this.$refs.subref[0].style.top = "0px";this.$refs.img[index].style.zIndex = ' 100'}
      if(index == 4){this.$refs.subref[0].style.top = "385px";this.$refs.img[index].style.zIndex = ' 100'}
      this.$refs.img[index].style.filter = ' opacity(1)'
    },
  },
}
</script>

<style lang="scss" scoped>
// 分页器的样式
.swiper-pagination {
  width: 100px;
  height: 30px;
  position: absolute;
  top: 850px;
  left: 300px;
  z-index: 1000;
}
// 按钮的active
.swiper-pagination-bullet-active {
  background: #fec981;
  opacity: 1;
}
// 按钮普通样式
.swiper-pagination-bullet {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: 5px solid #fec981;
  opacity: 1;
  background: #ffffff;
}
.myflexbe{
  display: flex;
  justify-content: space-between ;
}
.myflex{
  display: flex;
  justify-content: space-around ;
}
.myall{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.myleft{
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
}
.mycenter{
  display: flex;
  justify-content: space-evenly;
}
.bgmain2{
  background: url("../assets/index/yaoyuzhou-beijing.png")no-repeat;
  background-size: cover;
  width: 100%;
  z-index: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .totletitle{
    margin: 121px auto 12px;
    text-align: center;
    .title1{
      width: 84px;
      height: 59px;
      font-size: 42px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 59px;
    }
    .title2{
      margin-left: 18px;
      width: 151px;
      height: 40px;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 40px;
    }
  }
  .title3{
    margin: 0 auto ;
    font-size: 22px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(218,220,229,0.8);
    line-height: 22px;
  }
  .box-wrap{
    display: flex;
    justify-content: space-between;
    color: #FFFFFF;
    width: 1200px;
    margin: 0 auto 146px;
    .item-21{
      margin: 50px 0 0;
      .bgimg-2{
        width: 372px;
        height: 210px;
      }
      .box-21{
        position: relative;
        top: -43%;
        left: 50%;
        transform: translate(-50%, 80%);
        width: 308px;
        height: 144px;
        backdrop-filter: blur(1px);//毛玻璃属性
        background: linear-gradient(90deg,rgba(0,0,0,0.52),rgba(0, 10, 40, 1));
        border: #383737 1px solid ;
        border-radius: 10px;
        .imgicon-21{
          height: 69px;
          width: 66px;
          position: relative;
          left: 20px;
        }
        .text-21{
          height: 60px;
          line-height: 60px;
          font-size: 22px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
        }
        .text-22{
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 24px;
        }
        .text-wrap{
          text-align: left;
          width: 180px;
          .text-23{
            margin-top: 10px;
            margin-bottom: 3px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 21px;
          }
          .text-24{
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 18px;
          }
        }

      }
      .btn-21{
        background: linear-gradient(90deg, #007DDF 0%, #0047BB 100%);
        box-shadow: 0px 4px 0px 0px rgba(0,71,187,0.5);
        position: relative;
        z-index: 500;
        width: 220px;
        height: 66px;
        border-radius: 40px;
        text-align: center;
        line-height: 66px;
        margin: 0 auto;
        cursor: pointer;
        transition: all 0.5s linear;
        font-size: 22px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      }
      .btn-21:hover{
        opacity: 0.8;
      }
    }
  }
}
.bgmain3{
  background:#FAFAFA;
  position: relative;
  .width-fixed{
    width: 1200px;
    margin: 0 auto;
    position: relative;
  }
  .rightwrap{
    position: relative;
    .gather{
      width: 220px;
      height: 65px;
      line-height: 65px;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #0047BB;
      background: #FFFFFF;
      border-radius: 31px;
      position:absolute;
      top: 170px;
      right: 00px;
      z-index: 200;
      cursor: pointer;
      box-shadow: 0px 0px 20px 0px rgba(60,103,255,0.28);
      transition: all 0.5s linear;
    }
    .gather:hover{
      opacity: 0.8;
    }
    .exploit{
      cursor: pointer;
      width: 220px;
      height: 65px;
      line-height: 65px;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #0047BB;
      background: #FFFFFF;
      border-radius: 31px;
      position: absolute;
      top: 260px;
      right: 00px;
      z-index: 200;
      box-shadow: 0px 0px 20px 0px rgba(60,103,255,0.28);
      transition: all 0.5s linear;
    }
    .exploit:hover{
      opacity: 0.8;
    }
    .hardware{
      cursor: pointer;
      width: 220px;
      height: 65px;
      line-height: 65px;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #0047BB;
      background: #FFFFFF;
      border-radius: 31px;
      position: absolute;
      top: 350px;
      right: 00px;
      z-index: 200;
      box-shadow: 0px 0px 20px 0px rgba(60,103,255,0.28);
      transition: all 0.5s linear;
    }
    .hardware:hover{
      opacity: 0.8;
    }
  }
  .rightimg3{
    position: absolute;
    top: -560px;
    right: -1220px;
    width: 1694px;
    height: 1702px;
  }
  .leftimg3{
    position: absolute;
    width: 1987px;
    height: 879px;
    left: -1340px;
    top: 550px;
  }
  .flp{
    width: 1200px;
    margin: 0px auto 0;
    padding-top: 240px;
  }
  .boxwrap3{
    position: relative;
    z-index: 100;
    display: flex;
    .fourinner{
      width: 1200px;
      margin: 132px auto 0;
      display: flex;
      .itemfourwrap{
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .itemfour{
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        .upnum{
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 206px;
          height: 62px;
          background: url(../assets/index/neikuang.png)no-repeat;
          background-size: cover;
          padding: 8px;
          box-sizing: border-box;
          .leftnum{
            width: 46px;
            height: 46px;
            background: #3F61D0;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 25px;
          }
          .rightnum{
            text-align: right;
            margin-right: 13px;
            .title31{
              font-size: 16px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #FFFFFF;
              line-height: 22px;
            }
            .title32{
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 20px;
            }
          }
        }
        .topimg{
          margin-bottom: 19px;
        }
        .buildimg{
          margin-top: 18px;
          width: 300px;
          height: 254px;
          transition: all 0.5s linear;
        }
        .buildimg:hover{
          transform: scale(1.05);
        }
        .bottomicon{
          display: none;
          position: absolute;
          top:50px;
          margin-top: 7px;
          width: 294px;
          height: 350px;
          padding: 44px;
          box-sizing: border-box;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          background: url(../assets/index/four.png);
          transition: all 1s linear;
          .bgcolor{
            width: 206px;
            height: 52px;
            background: #DCE7F6;
            border-radius: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
              width: 48px;
              height: 51px;
              margin-right: 22px;
            }
          }
        }
        .bottomicon2{
          display: none;
          position: absolute;
          top:-60px;
          margin-top: 7px;
          width: 294px;
          height: 350px;
          padding: 44px;
          box-sizing: border-box;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          background: url(../assets/index/four.png);
          transition: all 1s linear;
          .bgcolor{
            width: 206px;
            height: 52px;
            background: #DCE7F6;
            border-radius: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
              width: 48px;
              height: 51px;
              margin-right: 22px;
            }
          }
        }
      }
      .actlow{
        position: relative;
        top: 104px;
      }
    }
  }
  .box-31{
    position: relative;
    text-align: left;
    .big-31{
      font-size: 42px;
      font-family: PingFangSC-Bold, PingFang SC;
      font-weight: bold;
      color: #333333;
      line-height: 72px;
    }
    .text-30{
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 30px;
    }
    .sub-31{
      font-size: 30px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #333333;
      line-height: 36px;
      font-weight: bold;
    }
    p{
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 30px;
    }
  }

  .box-wrap3{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 100;
    .item-31{
      .bgimg-3{
        height: 169px;
        width: 160px;
        margin-top: 60px;
        transition: all 0.5s linear;
      }
      .bgimg-3:hover{
        transform: translateY(-10px);
      }
      .text-32{
        position: relative;
        top: -25px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 24px;
      }
    }
  }
  .box-wrap3 :nth-child(10){
    opacity: 0;
  }

  .box-wrap3 :nth-child(1).item-31 {
    position: relative;
    left: -15px;
  }
  .box-wrap3 :nth-child(6).item-31 {
    position: relative;
    left: -15px;
  }
  .box-wrap3 :nth-child(2).item-31 {
    position: relative;
    left: -10px;
  }
  .box-wrap3 :nth-child(7).item-31 {
    position: relative;
    left: -10px;
  }
  .box-wrap3 :nth-child(4).item-31 {
    position: relative;
    left: 5px;
  }
  .box-wrap3 :nth-child(9).item-31 {
    position: relative;
    left: 5px;
  }
  .box-wrap3 :nth-child(5).item-31 {
    position: relative;
    left: 15px;
  }
  .box-wrap3 :nth-child(3).item-31 .bgimg-3 {
    position: relative;
    top: -8px;
  }
  .box-wrap3 :nth-child(8).item-31 .bgimg-3 {
    position: relative;
    top: -8px;
  }
  .box-wrap31{
    width: 1200px;
    margin: 160px auto 72px;
    position: relative;
    z-index:100;
    .item-32{
      padding: 28px 31px;
      width:294px;
      height:242px;
      background:#FFFFFF;
      border-radius:10px;
      box-sizing:border-box;
      text-align:left;
      box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.1);
      transition: all 0.5s linear;
      position: relative;
      text-align: center;
      .icontitle{
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 10px;
        position: relative;
        z-index: 1;
      }
      .absline{
        position: absolute;
        top: 60px;
        width: 262px;
        height: 7px;
        background: #DADCE5;
      }
      .imgicon3{
        width: 40px;
        height: 40px;
        position: relative;
        top: 2px;
      }
      .text-33{
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #333333;
        line-height: 25px;
      }
      .text-34{
        margin-top:50px ;
        display: flex;
        justify-content: center;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        line-height: 22px;
      }
      .shotline{
        width: 20px;
        height: 3px;
        margin: 6px 0 4px;
        background: #DADCE5 ;
      }
      .text-35{
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 18px;
      }
    }
    .item-32:hover{
      transform: translateY(-15px);
    }
  }
  .box-wrap31 :nth-child(1).item-32 .absline{left: 16px;}
  .box-wrap31 :nth-child(2).item-32 .absline{left: 16px;}
  .box-wrap31 :nth-child(3).item-32{padding: 28px 14px; }
  .box-wrap31 :nth-child(4).item-32{padding: 28px 14px; }
  .btn3wrap{
    height: 212px;
    position: relative;
    z-index: 500;
  }
  .btn3{
    cursor: pointer;
    overflow: hidden;
    margin: 0 auto 150px;
    box-sizing: content-box;
    width: 220px;
    height: 62px;
    background: linear-gradient(90deg, #007DDF 0%, #0047BB 100%);
    box-shadow: 0px 4px 0px 0px rgba(0,71,187,0.5);
    border-radius: 31px;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 62px;
    transition: all 0.5s linear;
  }
  .btn3:hover{
    opacity: 0.8;
  }
}
.bgmain4{
  .boxtop4{
    background: url(../assets/index/yaoyuzhou-beijing.png) no-repeat;
    background-size: cover;
    overflow: hidden;
    .title4{
      color: #FFFFFF;
      line-height: 48px;
      margin: 160px auto 23px;
      overflow: hidden;
      .title42{
        font-size: 42px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 59px;
      }
      .title43{
        font-size: 28px;
        font-family: PingFang SC;
        color: #FFFFFF;
        line-height: 34px;
        font-weight: bold;
      }
    }
    .title44{
      margin-bottom: 82px;
      height: 25px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #DADCE5;
    }
    .position4{
      position: relative;
      width: 1200px;
      height: 500px;
      margin: 0 auto;
      .Virtual_world{
        position: absolute;
        left: 220px;
        top:15px;
        width: 300px;
        height: 272px;
        transition: all 0.5s linear;
      }
      .Virtual_world:hover{
        transform: scale(1.05);
      }
      .jiantoudown{
        position: absolute;
        left: 510px;
        top: 100px;
        width: 371px;
        height: 110px;
      }
      .jiantou{
        position: absolute;
        left: 300px;
        bottom: 100px;
        width: 358px;
        height: 110px;
      }
      .Real_world{
        position: absolute;
        left: 685px;
        bottom: 60px;
        width: 300px;
        height: 261px;
        transition: all 0.5s linear;
      }
      .Real_world:hover{
        transform: scale(1.05);
      }
      .orientation{
        color: #ffffff;
        font-size: 16px;
        color: #FFFFFF;
        font-family: PingFangSC-Regular, PingFang SC;
        .up4{
          position: relative;
          left: 60px;
          top: 103px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 17px;
        }
        .down4{
          position: relative;
          left: -110px;
          top: 365px;

          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 17px;
        }
        .left4{
          position: relative;
          left: -280px;
          top: -15px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 17px;
        }
        .right4{
          position: relative;
          left: 190px;
          top: 185px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 17px;
        }
        .threebox{
          position: absolute;
        }
        .both4{
          height: 136px;
          width: 206px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          left: 0px;
          top: 60px ;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 24px;
          background: linear-gradient(180deg, rgba(0,71,187,0) 0%, rgba(0,16,66,0.8) 100%);
          border-radius: 8px;
          border: 1px solid rgba(255,255,255,0.2);
          backdrop-filter: blur(0px);
          .title46{
            margin-bottom: 15px;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 25px;
          }
        }
        .small4{
          background: url(../assets/index/small.png)no-repeat center;
          position: relative;
          left: 536px;
          top: -10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          line-height: 28px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 24px;
          width: 175px;
          height: 96px;
          background: linear-gradient(180deg, rgba(0,71,187,0) 0%, rgba(0,16,66,0.8) 100%);
          border-radius: 8px;
          border: 1px solid rgba(255,255,255,0.2);
          backdrop-filter: blur(0px);
          .title46{
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 25px;
            margin-bottom: 15px;
          }
        }
        .both41{
          background: url(../assets//index/wenzikuang.png);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          left: 992px;
          top: -10px ;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 24px;
          width: 206px;
          height: 136px;
          background: linear-gradient(180deg, rgba(0,71,187,0) 0%, rgba(0,16,66,0.8) 100%);
          border-radius: 8px;
          border: 1px solid rgba(255,255,255,0.2);
          backdrop-filter: blur(0px);
          .title46{
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 25px;
            margin-bottom: 15px;
          }
        }
      }
    }
    .title45{
      height: 20px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      margin: -20px auto 180px;
      width: 1210px;
      height: 47px;
      line-height: 47px;
      background: url(../assets/index/bottom.png)no-repeat center;
      background-size: cover;
    }
  }
  .box-wrap41{
    width: 1200px;
    margin: -80px auto 80px;
    .item-42{
      width: 282px;
      height: 161px;
      padding:17px 42px;
      background: #FFFFFF;
      display: flex;
      align-items: center;
      border-radius: 8px;
      box-sizing: border-box;
      box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
      transition: all 0.5s linear;
      .top42{
        display: flex;
        width: 197px;
        .img4{
          width: 66px;
          height:70px;
        }
        .text-41{
          text-align: left;
          font-size: 18px;
          font-family: PingFangSC-Bold, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 30px;
          margin: 25px 0 0 5px;
          box-sizing: border-box;
        }
      }
      .text-42{
        width: 197px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 28px;
        text-align: left;
      }
    }
    .item-42:hover{
      transform: translateY(-15px);
    }
  }
  .bottom4{
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 36px;
    margin: 145px auto 27px;
  }
}
.bgmain5{
  background: url(../assets/index/yaoyuzhou-beijing.png)no-repeat;
  background-size: cover;
  overflow: hidden;
  .titlewrap{
    margin: 0 auto;
  }
  .title51{
    text-align: center;
    width: 1200px;
    height: 70px;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 28px;
    margin: 165px auto 20px;
  }
  .layout{
    display: flex;
    margin:0 auto 80px;
    width: 1200px;
  }
  .itemwrap5{
    width: 723px;
    height: 800px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    .itemleft5{
      display: flex;
      width: 555px;
      position: relative;
      .actclassimg{
        z-index: 100;
        filter: opacity(1);
        transition: all 0.5s linear;
      }
      .img51{
        position: absolute;
        width: 700px;
        height: 200px;
        transition: all 0.5s linear;
      }
      .img51:hover{
        z-index: 100;
      }
    }
  }
  .itemwrap5 >.itemleft5:nth-child(1) > img{
    top: -10px;
    left: 20px;
    z-index: 50;
    width: 651px;
    height: 239px;
  }
  .itemwrap5 >.itemleft5:nth-child(2) > img{
    top: 145px;
    left: -10px;
    transition: 20px;
    z-index: 40;
    height: 204px;
    width: 714px;
  }
  .itemwrap5 >.itemleft5:nth-child(3) > img{
    top: 240px;
    z-index: 30;
    width: 709px;
    height: 237px;
  }
  .itemwrap5 >.itemleft5:nth-child(4) > img{
    top: 370px;
    z-index: 20;
    width: 680px;
    height: 235px;
  }
  .itemwrap5 >.itemleft5:nth-child(5) > img{
    top: 490px;
    z-index: 10;
    width: 682px;
    height: 235px;
  }
  .right5{
    padding-top: 140px;
    margin-left: 10px;
    .text51{
      height: 22px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 28px;
      margin-bottom: 100px;
    }
    .myline{
      height: 6px;
      width: 100%;
      background: #234EC3;
      position: relative;
      top: -90px;
    }
    .actclass{
      transform: translateX(26px) scale(1.4);
    }
    .actmyline{
      height: 2px;
      width: 177px;
      background: #234EC3;
      position: relative;
      top: -90px;
      transform: translateX(26px);
    }
  }
    .subitemwrap{
      padding-top: 140px;
      position: relative;
      margin-left: 74px;
      .subitem5{
        display: flex;
        flex-direction: column;
        width: 220px;
        height: 60px;
        background: url(../assets/index/btn.png)no-repeat center;
        background-size: cover;
        line-height: 60px;
        margin-bottom: 20px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
      }
    }
}
.bgmain6{
  background: #F2F6F9;
  position: relative;
  .left6{
    position: absolute;
    top: -300px;
    left: -770px;
  }
  .right6{
    position: absolute;
    top: 200px;
    right: -570px;
    z-index: 100;
  }
  .box-wrap6{
    width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 200;
    .item-6{
      width: 322px;
      margin: 200px auto;
      .itemtop6{
        margin: 0 auto;
        width: 282px;
        height: 117px;
        border-radius: 8px;
        background: #D8E2F2;
        .btn6{
          margin: -22px auto;
          width: 141px;
          height: 43px;
          border-radius: 30px;
          font-family: PingFangSC-Bold, PingFang SC;
          font-weight: 500;
          font-size: 20px;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 43px;
          background: #0047BB;
        }
        .text6{
          padding: 33px;
          p{
            font-size: 14px;
            display: flex;
            justify-content: space-between;
            span{
              text-align: left;
              width: 112px;
            }
          }
        }
      }
      .item-61{
        margin: 40px auto 0;
        width: 282px;
        height: 360px;
        background: #FFFFFF;
        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);
        border-radius: 8px;
        position: relative;
        transition: all 0.5s linear;
        overflow: hidden;
        .bofangimg{
          position: absolute;
          top: 69px;
          left: 128px;
          width: 30px;
          height: 31px;
          cursor: pointer;
        }
        .iteminner6{
          padding:10px 20px;
        }
        .text-61{
          text-align: left;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 25px;
        }
        .text-62{
          text-align: left;
          width: 238px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 28px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .btn61{
          cursor: pointer;
          width: 124px;
          height: 36px;
          border-radius: 26px;
          background: linear-gradient(90deg, #007DDF 0%, #0047BB 100%);
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 36px;
          float:right;
          margin-top: 10px;
          margin-bottom: 0px;
          transition: all 0.5s linear;
        }
        .btn61:hover{
          opacity: 0.8;
        }
      }
      .item-61:hover{
        transform: translateY(-20px);
      }
    }
  }
  .box-wrap6 :nth-child(2).item-6 .itemtop6 .text6{
    padding:33px 27px;
  }
}
.bgmain7{
  overflow: hidden;
  background: url(../assets/index/yaoyuzhou-beijing.png)no-repeat;
  background-size: cover;
  color: #ffffff;
  position: relative;
  z-index: 10;
  .right7{
    position: absolute;
    top: -600px;
    right: -570px;
    z-index: 100;
  }
  .title71{
    margin: 120px auto 20px;
    height: 48px;
    .span71{
      font-size: 42px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 59px;
    }
    .span72{
      font-size: 28px;
      font-family:PingFangSC-Medium, PingFang SC;
      color: #FFFFFF;
      line-height: 34px;
      font-weight: bold;
    }
  }
  .title72{
    margin: 0 auto 130px;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 22px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #DADCE5;
    line-height: 25px;
  }
  .totle71{
    display: flex;
    justify-content:space-between;
    width: 1200px;
    margin: 0 auto;
    .mynpcwrap{
      display: flex;
      justify-content: space-between;
    }
    .mynpc{
      width: 70px;
      height: 71px;
    }
    .mynpc2{
      width: 70px;
      height: 79px;
    }
    .mynpc3{
      width: 70px;
      height: 62px;
    }
    .newright{
      position: relative;
      left: 10px;
    }
    .box71{
      display: flex;
      align-items: center;
      .box72{
        background: linear-gradient(180deg, rgba(0,71,187,0) 0%, rgba(0,16,66,0.8) 100%);
        border: 1px solid rgba(255,255,255,0.2);
        backdrop-filter: blur(0px);
        position: relative;
        background: #051240;
        width: 314px;
        height: 344px;
        position: relative;
        padding: 18px 10px;
        box-sizing: border-box;
        border-radius: 5px;
        .title73{
          position: absolute;
          top: 30px;
          left: 20%;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 22px;
        }
        .title79{
          margin: 20px auto 0;
          width: 176px;
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 22px;
        }
        .title74{
          position: absolute;
          top: 127px;
          left: 20%;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 22px;
        }
      }
      .box-right{
        position: relative;
        left: -10px;
      }
      .biaotibox{
        text-align: center;
        line-height: 80px;
        height: 94px;
        width: 100px;
        background: url(../assets/index/biaoti.png)no-repeat center;
        background-size: cover;
        font-size: 20px;
        color: #FFFFFF;
      }
      .rightbox{
        position: relative;
        right: -18px;
        top: -25px;
        z-index: 100;
      }
      .leftbox{
        position: relative;
        left: -28px;
        top: -25px;
        z-index: 100;
      }
    }
    .toptext{
      margin-top: 10px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 24px;
    }
    .bottomtext{
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 24px;
      margin-top: -13px;
    }
    .lefttext{
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 24px;
      position: relative;
      top: -5px;
    }
    .box73{
      display: flex;
      align-items: center;
      flex-direction: column;
      .top71{
        height: 178px;
        width: 342px;
        background: #05143A;
        box-sizing: border-box;
        padding: 22px;
        border-radius: 6px;
        background: linear-gradient(180deg, rgba(0,71,187,0) 0%, rgba(0,16,66,0.8) 100%);
        border: 1px solid rgba(255,255,255,0.2);
        backdrop-filter: blur(0px);
        display: flex;
        flex-direction: column;
        align-items: center;
        .p71{
          height: 47px;
          width: 256px;
          background: #1F47B9;
          border-radius: 4px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 43px;
          border: #0047BB 1px solid;
        }
        .mynpcwrap{
          display: flex;
          justify-content: space-between
        }
      }
      .biaotiagi{
        text-align: center;
        line-height: 80px;
        height: 94px;
        width: 100px;
        background: url(../assets/index/biaoti.png)no-repeat;
        background-size: cover;
        font-size: 20px;
        font-family:PingFang SC;
        color: #FFFFFF;
      }
      .topbox{
        position: relative;
        top: 12px;
        z-index: 100;
      }
      .bottombox{
        position: relative;
        top: -12px;
        z-index: 100;
      }
      .zubiao{
        width: 360px;
        height: 179px;
        background: url(../assets/index/zhubiao.png)no-repeat;
        font-size: 36px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(255,255,255,0.9);
        line-height: 54px;
        text-shadow: 0px 0px 10px #98DEFF;
        margin: 10px auto 30px;
        padding-top: 10px;
        p{
          font-size: 32px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(255,255,255,0.9);
          line-height: 45px;
          text-shadow: 0px 0px 10px #98DEFF;
        }
      }
    }
  }
  .btn71{
    display: flex;
    justify-content: space-between;
    width: 820px;
    margin: 50px auto 150px;
    .btnsolo{
      cursor: pointer;
      width: 220px;
      height: 62px;
      line-height: 62px;
      background: linear-gradient(90deg, #007DDF 0%, #0047BB 100%);
      box-shadow: 0px 4px 0px 0px rgba(0,71,187,0.5);
      border-radius: 31px;
      transition: all 0.5s linear;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
    }
    .btnsolo:hover{
      opacity: 0.8;
    }
  }
}
@keyframes myBreath {
  0%{
    transform: scale(0.88);
  }
  50%{
    transform: scale(1.1);
  }
  100%{
    transform: scale(0.88);
  }
}
  </style>
